body {
  background-color: black;
  width: 100%;
}

.container {
  width: 70% !important;
  /* color: #313149; */
  padding: 5%;
  padding-top: 4%;

  justify-content: center;
  margin: 3% 15% 3% 15%;
  position: relative;
  z-index: 0;
}

.container:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 38px;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.option {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1%;
  width: 90% !important;
}

.name {
  font-size: 100%;
  width: 30%;
  color: white;
  margin-left: 2%;
  margin-right: 2%;
}

.bar {
  border-radius: 38px;
  width: 100% !important;
  height: 20%;
  background-color: #edb0ff;
  margin: 1% 1% 1% 1%;
}

.fill {
  border-radius: 38px;
  height: 40px;
  background-color: #865dff;
}

.votes {
  font-size: 100%;
  color: white;
  width: 3%;
}


.option1 {
  display: none;
}

.name1 {
  display: none;
}

.bar1 {
  display: none;
}

.fill1 {
  display: none;
}

.votes1 {
  display: none;
}

/* .glowhover1 {
  display: none;
}

.glowhover1:before {
  display: none;
} */
/* 
.glowhover1:active {
  display: none;
}

.glowhover1:active:after {
  display: none;
}

.glowhover1:hover:before {
  display: none;
}

.glowhover1:after {
  display: none;
} */

.Title h2 {
  color: white;
  text-align: center;
  font-size: 150;
}

.Title h3 {
  color: white;
  text-align: center;
  font-size: 130%;
}

.container p {
  color: white;
  font-size: 150%;
  text-align: center;
  margin-bottom: 2%;
}

.glowhover {
  border: 2px solid;
  border-radius: 50%;
  background: transparent;
  color: white;
  margin-right: 5%;
  padding: 1% !important;
  height: 16%;
  width: 16%;
  border: none;
  outline: none;
  color: #fff;
  background: #fff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 50%;
}

.glowhover:before {
  content: '';
  background: linear-gradient(45deg,
      #4300fe,
      #6027ff,
      #7b4dff,
      #a477c5,
      #edb0ff,
      #865dff,
      #4300fe);
  position: absolute;
  top: -1%;
  left: -1%;
  background-size: 100%;
  z-index: -1;
  filter: blur(2px);
  width: calc(100% + 2%);
  height: calc(100% + 2%);
  animation: glowing 10s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glowhover:active {
  color: #fff;
}

.glowhover:active:after {
  background: transparent;
}

.glowhover:hover:before {
  opacity: 1;
}

.glowhover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #895fff;

  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@media (max-width: 768px) {

  .option,
  .glowhover,
  .name,
  .bar .fill .votes {
    display: none;
  }

  .container {
    padding: 30px 30px;
    height: 70% !important;
  }

  .option1,
  .glowhover1,
  .name1,
  .votes1 {
    display: inline-block;
    width: calc(33.33% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .bar1,
  .fill1 {
    display: block;
    width: 100%;
    margin-right: 0;
  }

  .option1 {
    color: white;
    width: 100% !important;
  }

  .name1 {
    padding: 0;
    font-size: 70%;
    width: 30%;
    color: white;
    margin-left: 10px;
  }

  .bar1 {
    border-radius: 38px;
    width: 100% !important;
    height: 20%;
    background-color: #edb0ff;
    margin: 1% 1% 1% 1%;
  }

  .fill1 {
    border-radius: 38px;
    height: 20px;
    background-color: #865dff;
  }

  .votes1 {
    font-size: 100% !important;
    color: white;
    width: 1% !important;
    margin-left: 40%;
  }

  /* button {
    border: 2px solid;
    border-radius: 50% !important;
    background: transparent;
    color: white !important;
    margin-right: 5%;
    padding: 1%;
    height: 16%;
    width: 16%;
    font-size: 100%;
  } */

  .glowhover1 {
    width: 15px !important;
    height: 15px;
    border: none;
    outline: none;
    color: #fff;
    background: #fff;
    cursor: pointer !important;
    position: relative;
    z-index: 0;
    border-radius: 38px;
  }

  .glowhover1:before {
    content: '';
    background: linear-gradient(45deg,
        #4300fe,
        #6027ff,
        #7b4dff,
        #a477c5,
        #edb0ff,
        #865dff,
        #4300fe);
    position: absolute;
    top: -1%;
    left: -1%;
    background-size: 100%;
    z-index: -1;
    filter: blur(2px);
    width: calc(100% + 2%);
    height: calc(100% + 2%);
    animation: glowing 10s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  .glowhover1:active {
    color: #000;
  }

  .glowhover1:active:after {
    background: transparent;
  }

  .glowhover1:hover:before {
    opacity: 1;
  }

  .glowhover1:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
}

hovered .fill {
  background-color: #865dff;
}

.hovered::after {
  content: 'pranita';
  position: absolute;
  top: 70%;
  left: 60%;
  transform: translateX(-50%);
  background-color: black;
  color: #edb0ff;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  white-space: nowrap;
}

.hovered::before {
  content: '';
  position: absolute;
}

.blackBox {
  margin-left: 80%;
  margin-top: 21%;
  position: absolute;
  top: -30px;
  left: 0;
  display: none;
  width: 100px;
  height: auto;
  background-color: black;
  color: #edb0ff;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  border-radius: 10px;
}

.votes:hover+.blackBox {
  display: block;
}