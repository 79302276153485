.add-comment {
  display: flex;
  flex-direction: row;
  /* gap: 20px; */
  width: 100%;
  background: transparent;
  border-radius: 10px;
  /* border: solid white 1px; */
  animation: come-in 1s ease-in-out;
}




.profile-pic {
    width: 50px;
    height: 50px;
    background-size: cover;
    border-radius: 100%;
    margin-right: 10px;
    border: solid gray 1px;
  }




  .comment-input {
    margin-right: 1.5%;
    position: relative;
    height: 50px;
    width: 80%;
    background-color: black;
    border: 2px solid grey;
    color: white;
    border-radius: 30px;
    padding-top: 1.2%;
    padding-left: 1.5%;
  }

  .send-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .add-btn {
    border: solid gray 2px;
    color: white;
    font-weight: bold;
    font-size: 80%;
    text-transform: uppercase;
    padding-bottom: 13px !important;
    padding-top: 13px !important;
    border-radius: 30px;
    transition: 0.3s;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    background-color: black !important;
  }
/* 
  @include media-md() {
    width: 735px;
    flex-direction: row;
    padding: 25px;
    gap: 25px;

    > .profile-pic {
      width: 50px;
      height: 36px;
      display: block;
      margin: 0;
    }

    .comment-input {
      width: 100%;
    }

    .send-btn-container {
      align-items: flex-start;

      .profile-pic {
        display: none;
      }
    }
  } */


@keyframes come-in {
  0%{
    opacity: 0;
    transform: translateY(-100%);
  }

  75%{
    opacity: 0;
    transform: translateY(-50%);
  }

  100%{
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .add-btn{
    font-weight: 100;
    font-size: 60%;
    padding-left: 5px;
    padding-right: 5px;
  }
    .comment-input .comment-text {
  font-size: 12px !important; 
  margin-left: 10px;
}
.profile-pic {
  width: 60px;
    height: 40px;
    background-size: cover;
    border-radius: 100%;
    margin-right: 10px;
    border: solid gray 1px;
}

}