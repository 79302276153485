.faqContainer {
  padding-top: -4%;
  padding-bottom: 10% !important;
  width: 100vw;
  padding: 0;
  background-color: #222222 !important;
}

.head {
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #ffffff;
}

.summary {
  width: 80vw;
  margin: auto;
  font-size: 1.25rem;
  font-weight: 600;
  border: 2px solid #855dff;

  color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 100px;
  text-align: left;
  cursor: pointer;
  position: relative;
}

.para {
  width: 80vw;
  margin: auto;
  color: #fff;
  margin-bottom: 10px;
  text-align: justify;
}

.faq__content {
  width: 80vw;
  color: #fff;
  margin-bottom: 10px;
}

details>summary::after {
  position: absolute;
  content: '+';
  right: 20px;
}

details[open]>summary::after {
  position: absolute;
  content: '-';
  right: 20px;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}


@media only screen and (min-width: 800px) and (max-width: 900px){
  .faqContainer{
    margin-top: 0%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
  .faqContainer {
    margin-top: 20%;
  }
}