.PostContainerWrap {
  width: 80%;
  padding: 0.05rem;
  border-radius: 30px;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  margin: 0 auto;
  margin-top: 10%;
  margin-bottom: 10%;
}

.likedList{
  width: max-content;
  position: absolute !important;
  top: 100% !important;
  z-index: 99;
  border-radius: 20px;
  color: #865dff;
  background-color: rgba(0, 0, 0, 0.8);
}

.likedNames{
  margin: 10px;
}

.whoLikedBtn{
  padding: 2% !important;
  width: max-content !important;
  height: 80%;
}


.PostContainer {
  background-color: #222;
  padding: 0.5rem;
  border-radius: 30px;
  color: white;
}

.PostHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  /* border-bottom: 2px solid #7746ff; */
}

.caption {
  margin-bottom: 1rem;
}

.PostHeaderLeft {
  display: flex;
  align-items: center;
  padding: 1rem;
  min-width: 100px;
}

.PostHeaderLeftText {
  padding: 0.5rem;
}

.PostHeaderLeftText h3,
.PostHeaderRightText h3 {
  margin: 0;

  font-weight: 700;
}

.postHeaderRight {
  display: flex;
  align-items: center;
  min-width: 50px;
}

.postHeaderRightBtn{
  width: 100% !important;
  display: flex;
  align-items: center;
  gap: 2px;
  border-width: 0;
}

.postHeaderRight button:hover{
  opacity: 0.5;
}


.PostFooterMiddle div:hover {
  background-color: white;
  color: black;
  cursor: pointer;
  transition: 0.3s;
}

.ProileHeaderLeft img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.ProfilePic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #865dff;
}

.PostFooter {
  margin-top: 3%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.PostFooterLeft {
  display: flex;
  flex-direction: row;
  width: 210px;
}


.PostFooterLeft button {
  display: flex;
  flex-direction: row;
  width: auto;
  border-radius: 10px;
  padding: 10px !important;
  margin: 5px;
  border-width: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  box-shadow: 2px 2px 10px black;
}

.PostFooterLeft button:hover {
  /* background-color: white;
  color: black; */
  opacity: 0.5;
  transition: 0.3s;
}

.PostFooterRight {
  display: flex;
  flex-direction: row;
}

.PostFooterRightBtn {
  display: flex;
  flex-direction: row;
  width: auto;
  border-radius: 10px;
  border-width: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  box-shadow: 2px 2px 10px black;
  padding: 10px !important;
  margin: 5px;
}

.PostFooterRightBtn:hover {
  /* background-color: white;
  color: black; */
  opacity: 0.5;
  transition: 0.3s;
}

.PostFooterMiddle button{
  
}



.iconsfc{
  align-items: center;
  margin-top: 5%;
  margin-right: 5%;
}

.profileLink:hover{
  color: white;
  opacity: 0.5;
}

.PostContent {
  display: flex;
  align-items: center;
  /* height: 42vw; */
  max-height: 50vw;
  background-color: black;
}


.tagLink{
  color: #865dff;
}

.tagLink:hover{
  color: #1c86e3;
}

@media only screen and (max-width: 600px){
  .PostContainerWrap{
    width: 94% !important;
    margin-left: 3%;
    margin-right: 3%;
  }
}