.FeedContainer {
    flex: 4;
    background-color: #222222;
    padding-top: 30px;
    overflow-y: scroll;
    transition: left 0.5s ease;
    padding-right: 25%;
    padding-left: 25%;
    align-items: center;
    justify-content: center;
}

.FeedContainer::-webkit-scrollbar {
    width: 0px;
}

.FeedContainer::-webkit-scrollbar-thumb {
    background-color: #865dff;
    border-radius: 10px;
}

.FeedContainer::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #865dff; /* Blue border on top */
    border-radius: 50%; /* Make it round */
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite; /* Apply animation */
    margin: 20px auto; /* Center the loader */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

