@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.main-container-background{
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
}
.uploadHeading{
	color: #7b2cbf;
	font-size: x-large;
}
.close-btnn{
	color: black;
	position: absolute;
	top: 20vh;
	right: 20vw;
	padding: 0;
	width: 8%;
	height: 5%;
	border: 0px;
	font-size: 100%;
}
.close-btnn:hover{
	color: red;
}
.upload-files-container {
	background-color: #f7fff7;
	width: 60vw;
    height: 60vh;
	padding: 30px 60px;
	border-radius: 40px;
	display: flex;
   	align-items: center;
   	justify-content: center;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
}
.drag-file-area {
	display: flex;
	flex-direction: column;
	gap: 15%;

	align-items: center;
	padding-top: 6%;
	border: 2px dashed #7b2cbf;
	border-radius: 40px;
	width: 90%;
	height: 60%;
    max-height: 80%;
	margin: 2%;
}
.secondStep{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
	height: 80%;
	margin: 3%;
}
#caption{
	background-color: gray;
	width: 45%;
	height: 60%;
}
.image-container{
	display: flex;
	align-items: center;
	justify-self: center;
	width: 40%;
	overflow: hidden !important;
}
.image{
	object-fit:cover ;
	/* height: 100%; */
	width: 100%;
}
.drag-file-area .upload-icon {
	font-size: 300%;
}
.drag-file-area h3 {
	font-size: 200%;
}

.label{
	width: 100% !important;
	display: flex;
	/* overflow: hidden; */
	gap: 5px;
	justify-content: center;
	align-items: center;
	font-size: 150%;
}


.label .browse-files-text{
	color: #7b2cbf;
	font-weight: bolder;
	cursor: pointer;
}
.default-file-input {
	opacity: 0;
}
.cannot-upload-message {
	background-color: #ffc6c4;
	font-size: 3%;
	display: flex;
	align-items: center;
	border-radius: 5px;
	color: #BB0000;
	display: none;
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.cannot-upload-message span, .upload-button-icon {
	padding-right: 10px;
}
.cannot-upload-message span:last-child {
	padding-left: 20px;
	cursor: pointer;
}
.file-block {
	color: #f7fff7;
	background-color: #7b2cbf;
  	transition: all 1s;
	width: 50%;
	position: relative;
	display: none;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0 15px;
	padding: 10px 20px;
	border-radius: 25px;
	cursor: pointer;
}
.file-info {
	display: flex;
	align-items: center;
	font-size: 10%;
}
.file-icon {
	margin-right: 10px;
}
.file-name, .file-size {
	padding: 0 3px;
}
.remove-file-icon {
	cursor: pointer;
}
.progress-bar {
	display: flex;
	position: absolute;
	bottom: 0;
	left: 4.5%;
	width: 0;
	height: 5px;
	border-radius: 25px;
	background-color: #4BB543;
}
.upload-button {
	/* font-family: 'Montserrat'; */
	font-size: larger !important;
	background-color: #7b2cbf !important;
	color: #f7fff7;
	border-radius: 20px;
	padding-top: 1%;
	padding-bottom: 1%;
	padding-right: 2%;
	padding-left: 2%;
}

.caption-other{
	width: 40%;
	display: flex;
	flex-direction: column;
	color: #000;
	justify-content: space-between;
}

.giveLeftMargin{
	margin-left: 40%;
}

.button-container{
	display: flex;
	justify-content: center;
}

.edit-delete-button{
	/* width:40%; */
	border: solid 1px black;
	height: 100%;
	border-radius: 20px;
	padding: 2%;
	padding-right: 5%;
	padding-left: 5%;
}

.edit-delete-button:hover{
    background-color: rgba(0, 0, 0, 0.1);
}



/* text area */
.talkbubble {
	color: black;
	position: relative;
	width: 100%;
	height: 70%;
	padding: 10px;
	border-radius: 5px;
	border: solid lightblue;
	background: lightblue;
	overflow-y: scroll;
  }

  .mentionContainer{
	padding: 50px !important;
	width: 100px;
	height: 100px;
  }

  
  .talkbubble:before {
	content:"";
	position: absolute;
	right: 100%;
	top: 26px;
	width: 0;
	height: 0;
	
	border-top: 13px solid transparent;
	border-right: 26px solid lightblue;
	border-bottom: 13px solid transparent;
  }
  
  .talkbubble textarea {
	position: inherit;
	width: 100%;
	height: 100%;
	background: inherit;
	
	margin: 0;
	padding: 0;
	
	border: none;
	resize: none;
  }

  @media only screen and (max-width: 600px){
	.upload-files-container {
			background-color: #f7fff7;
			width: 90vw;
			height: 60vh;
			padding: 30px 60px;
			border-radius: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
		}

		.browse-files-text{
			font-size: 20px;
		}
  }