.ChildContainer {
  display: flex;
  height: 100vh;
  padding-top: 8vh;
}



.physicalCopyLink:hover{
  color: #865Dff !important;
}

.uploadPostBtn{
  border-width: 0;
  background-color: #865Dff;
  height: 70%;
  margin-top: 1%;
  border-radius: 20px;
  width: 40%;
  font-size: 120%;
  margin-left: 5%;
}

.Notif {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: black;
  color: #fff;
}

.btnContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.active {
  position: fixed;
  top: 100px;
  background-color: black;
  opacity: 0.8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  width: 100%;
  transform: translateY(-2em);
  overflow-y: hidden;
  overflow-x: hidden;
}

.notactive {
  position: fixed;
  top: 70px;
  background-color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 0px;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  width: 100%;
  transform: translateY(-2em);
  overflow-y: hidden;
  overflow-x: hidden;
}

.menu {
  text-decoration: none;
  font-size: 1.4rem;
  width: 100%;
}

.ParentContainer {
  display: flex;
  width: 100%;
  /* min-height: 100vh; */
  height: 100vh;
  background-color: #222222;
  padding-top: 8.6vh;
}

.mainContainer{
  display: flex;
  flex-direction: column;
  width: 83% ;
}

.mainContainerSubChange{

  width: 66% !important;

}
.container {
  position: relative;
  left: -100%; /* Start off-screen to the left */
  transition: left 0.5s ease; /* Transition property, duration, and easing */
}

.container-enter {
  left: -100%;
}

.container-enter-active {
  left: 0;
  transition: left 0.5s ease;
}

.container-exit {
  left: 0;
}

.container-exit-active {
  left: -100%;
  transition: left 0.5s ease;
}
.ParentContainer1{
  display: none;
}
.mainContainer1{
  display: none;
}
.btnContainer1{
  display: none;
}

@media only screen and (max-width: 600px){
  .ParentContainer1{
    display: flex;
    flex-direction: column;
    width: 100%;
      /* min-height: 100vh; */
    height: 20vh;
    background-color: #222222;
    padding-top: 8.6vh;

  }
  .mainContainer1{
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 8vh;
  
  }
  .btnContainer1{
    display: flex;
    flex-direction: column;
    
    height: 10px !important;
    width: 100%;

  }
  .ParentContainer, 
  .mainContainer,
  .btnContainer{
    display: none;
  }
   
  .circular-button {
    position: absolute;
    top: 75px !important;
   
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e7ddb9;
  }

  .popup {
    position: fixed;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    background-color: black;
    padding: 20px;
    z-index: 999;
    overflow: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
  }

  .popup-content{
    width: 100%;
    height: 100%;
    border: none;
  }
  /* .btnContainer  .popup1 {
    position: fixed;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    background-color: black;
    padding: 20px;
    z-index: 999;
    overflow: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .btnContainer .close-button1 {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
  }

  .btnContainer .popup-content1 {
    width: 100%;
    height: 100%;
    border: none;
  } */
}