.body {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.main-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 20%;
  left: 20%;
  z-index: 1;
  background-color: white;
  height: 65vh;
  width: 60vw ;
  border-radius: 30px;
  box-shadow: 5px 5px 20px 0.1px #865dff;
}

.write-post-main-text{
  color: #000 !important;
  margin-bottom: 15%;
}

.write-post-main-text p {
  color: #000 !important;
} 


.write-post-header {
  width: 100%;
  color: black;
  padding: 1%;
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: center;
}

.write-post-header h3 {
  font-size: 200%;
  color: #865dff;
}

.close-btn {
  position: absolute;
  right: 2% !important;
  top: 2% !important;
  color: black !important;
}

.close-btn:hover {
  color: red !important;
}

.write-post-main {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10%;
  height: 15vh;
}

.write-post-main-left {
  margin-left: 12%;
  width: 33%;
}

.write-post-main-middle {
  width: 33%;
  margin-left: -5%;
  /* margin-top: 80px; */
}

.write-post-main-right {
  width: 33%;
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 0 !important;
}

.write-post-main-middle img {
  margin-bottom: 0vh;
  width: 10vw;
  height: 8vw;
}

.writing-area {
  display: flex;
  width: 100%;
}

/* text area */
* {
  outline: none;
  border: none;
  margin: 0px;
  padding: 0px;
  /* font-family: Courier, monospace; */
}
body {
  background: #333
    url(https://static.tumblr.com/maopbtg/a5emgtoju/inflicted.png) repeat;
}
#paper {
  color: #fff;
  font-size: 20px;
}
#margin {
  margin-left: 12px;
  /* margin-bottom:20px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#text {
  margin-bottom: 0;
  height: 100% !important;
  width: 80%;
  overflow: hidden;
  background-color: #fff;
  color: #222;
  font-weight: normal;
  font-size: medium;
  resize: none;
  line-height: 40px;
  padding-left: 100px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-image: url(https://static.tumblr.com/maopbtg/E9Bmgtoht/lines.png),
    url(https://static.tumblr.com/maopbtg/nBUmgtogx/paper.png);
  background-repeat: repeat-y, repeat;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 2px 14px #000;
  box-shadow: 0px 2px 14px #000;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

#button {
  margin-top: 5px !important;
  border-radius: 20px;
  height: 20%;
  margin-top: 0;
  width: 80%;
  cursor: pointer;
  background-color: black;
  color: white;
  width: 25%;
}

#button:hover {
  zoom: 1;
  filter: alpha(opacity=80);
  opacity: 0.8;
}

#wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 35vh;
  margin-left: auto;
  margin-right: auto;
}

#checkbox-label {
  display: flex;
  align-items: center;
}

#checkbox-input {
  margin-right: 10px;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  border: 2px solid #666;
  transition: all 0.3s;
}

#checkbox-input:checked {
  background-color: #666;
}

#checkbox-text {
  font-size: 16px;
  color: #000;
}


.anony{
  display: block !important;
  width: 15%;
}


@media only screen and (max-width: 600px) {
  .search-input {
    margin-bottom: 1rem;
  }

  .main-container{
    display: flex;
      flex-direction: column;
      position: fixed;
      top: 20%;
      left: 10%;
      z-index: 1;
      background-color: white;
      height: 65vh;
      width: 80vw;
      border-radius: 30px;
      box-shadow: 5px 5px 5px 20px 0.1px #865dff;
  }
    .writing-area {
      display: flex;
      width: 110%;
      margin-left: -4% !important;
      margin-top: 5% !important;
    }
  
    .write-post-main-text{
      color: #000 !important;
      font-size: 15px;
      margin-bottom: 1%;
    }
    .write-post-header h3{
      font-size: 25px;
    }
    .close-btn{
      font-size: 13px;
    }
      
    
}

