.RightBar {
  flex: 1;
  border-left: 0.5px solid #865dff;
  color: #fff;
  padding: 10px;
  padding-top: 30px;
  overflow-y: hidden;
  background-color: #222222 !important;
}

.SortWrapper{
  margin-bottom: 20px;
} 

.wrapperhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: #865dff;
  text-transform: uppercase;
  margin-bottom: 10px;
  border-bottom: #865dff 1px solid;
  padding: 2px;
  font-size: 130%;
}

.choice {
  position: relative;
  padding: 10px;
  font-size: 14px;
  display: flex;
  gap: 20px;
  margin: 2px 0 2px 0;
  border-radius: 10px;
  background: #222222;
  transition: background 0.5s linear;
}

.choice:hover {
  background-color: #865dff;
  color: #fff;
  cursor: pointer;
}
