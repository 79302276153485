.PostHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 1rem; */
    /* border-bottom: 2px solid #7746ff; */
}
.mainContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

  .PostHeaderLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50px;
    width: 100%;
    max-height: 30vh !important;
    overflow-y: scroll;
    border: solid white 1px;
    border-radius: 7px;
    border-top-right-radius: 0px;
    margin-top: 10%;
    padding: 2%;
  }

  .userContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between ;
    align-items: center;
  }

  .PostHeaderLeftText h3{
    font-size: large !important;
  }

  .PostHeaderLeftText {
    font-size: x-small;
  }
  
  .PostHeaderLeftText h3,
  .PostHeaderRightText h3 {
    margin: 0;
    font-weight: 400;
  }

  .ProfilePic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 0.5px solid white;
  }

  .containUser{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
  }

  .userListLi{
    width: 100%;
    border-radius: 7px;
    padding: 2%;
    /* border-bottom: solid 1px white; */
  }


  .userListLi:hover{
    background-color: #865Dff;
  }

  .userListLi:hover .PostHeaderLeftText p{
    color: black !important;
  }


  .PostHeaderLeft::-webkit-scrollbar {
    width: 3px;
  }
  
  .PostHeaderLeft::-webkit-scrollbar-thumb {
    background-color: #865dff;
    border-radius: 10px;
  }
  
  .PostHeaderLeft::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }