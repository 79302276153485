.NotifWrapContainer {
  background-color: #222;
  width: 100%;
}

.NotifContent
{
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #7746FF;
}

.NotifPic{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #865DFF;
  margin-right: 1%;
}

.menu {
  text-decoration: none;
  font-size: 1.4rem;
  width: 100%;
}

.Notif {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: black;
  color: #fff;
}

.active {
  position: fixed;
  top: 90px;
  background-color: black;
  opacity: 0.8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  width: 100%;
  transform: translateY(-2em);
  overflow-y: hidden;
  overflow-x: hidden;
}

.notactive {
  position: fixed;
  top: 70px;
  background-color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 0px;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  width: 100%;
  transform: translateY(-2em);
  overflow-y: hidden;
  overflow-x: hidden;
}

.ProfilePic {
  background-color: white;
  width:50px;
  height: 50px;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  border: 3px solid #865dff;
}
