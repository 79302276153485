.SideBar {
  display: flex;
  flex-direction: column;
  width: 17.5vw;
  margin: 0 auto;
  color: white;
  border-right: 1px solid #7746ff;
  padding-top: 20px;
  overflow-y: hidden;
  background-color: #222222 !important;
  padding: 10px;
}

.HeaderText {
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #7746ff;
}

.FriendsList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.Friend {
  display: flex;
  align-items: center;
  width: 100%;
}

.FriendText {
  padding-left: 10px;
}
