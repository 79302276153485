.PostContainerWrap {
  width: 80%;
  padding: 0.05rem;
  border-radius: 30px;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  margin: 0 auto;
  margin-top: 10%;
  margin-bottom: 5%;
}

.tagLink{
  color: rgb(188, 222, 251);
}

.profileLink:hover{
  color: white;
  opacity: 0.5;
  
}


.likedList{
  width: max-content !important;
  position: absolute !important;
  top: 100% !important;
  z-index: 99;
  border-radius: 20px;
  color: #865dff;
  background-color: rgba(0, 0, 0, 0.8);
}

.likedNames{
  margin: 10px;
}


.whoLikedBtn{
  padding: 2% !important;
  width: max-content !important;
  height: 100%;
}


.iconsfc{
  align-items: center;
  margin-top: 5%;
  margin-right: 5%;
}

.PostContainer {
  background-color: #222;
  padding: 0.5rem;
  border-radius: 30px;
  color: white;
  position: relative;
}

.deletePostBtn{
  font-size: 100%;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center !important;
  padding: 20px !important;
  height: 47px;
  width: 47px !important;
  margin-left: 10px !important;
}

.editPostBtn{
  font-size: 100%;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center !important;
  padding: 20px !important;
  height: 47px;
  width: 67px !important;
  margin-left: 10px !important;
  gap: 2px;
}

.deletePostBtn:hover{
  opacity: 0.5;
}

.PostHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 2px solid #7746ff;
}

.PostHeaderLeft {
  display: flex;
  align-items: center;
  padding: 1rem;
  min-width: 100px;
  width: 100%;
}

.PostHeaderLeftText {
  padding: 0.5rem;
}

.PostHeaderLeftText h3,
.PostHeaderRightText h3 {
  margin: 0;
  font-size: larger;
  color: #865dff;
  font-weight: 700;
}

.PostHeaderRight {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  min-width: 100px;
  width: 100%;
}


.PostFooterMiddle div:hover {
  background-color: white;
  color: black;
  cursor: pointer;
  transition: 0.3s;
}

.ProileHeaderLeft img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.ProfilePic {
  background-color: white;
  width:50px;
  height: 50px;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  border: 3px solid #865dff;
}

.PostFooter{
  margin-top: 3%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.PostFooterLeft{
  display: flex !important;
  justify-content: center;
  margin: 0;
  margin-left: 2%;
  width: auto !important;
}

.PostFooterLeft button{
  display: flex;
  flex-direction: row;
  width: auto;
  border-radius: 10px;
  padding: 10px !important;
  border-width: 0;
  box-shadow: 2px 2px 10px black;
}

.PostFooterLeft button:hover{
  opacity: 0.5;
  transition: 0.3s;
}



.PostFooterRight button{
  display: flex;
  flex-direction: row;
  width: auto !important;
  border-radius: 10px;
  padding: 10px !important;
  border-width: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  margin-left: 2%;
  box-shadow: 2px 2px 10px black;
}

.PostFooterRight button:hover{
  /* background-color: white;
  color: black; */
  opacity: 0.5;
  transition: 0.3s;
}

.PostFooterRight{
  display: flex;
  justify-content: center;
  width: 40% !important;
}


.PostFooterMiddle button{
  display: flex;
  flex-direction: row;
  width: auto;
  border-radius: 10px;
  padding: 10px !important;
  border-width: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.PostFooterMiddle button:hover{
  background-color: white;
  color: black;
  transition: 0.3s;
}


.PostContent{
  padding: 2%;
}
@media only screen and (max-width: 600px){

  .PostHeaderLeftText h3{
    margin: 0;
    font-size: 120% !important;
    color: #865dff;
    font-weight:400;
    text-align: left;
    margin-top: 10%;
    
  }

  .PostHeaderRightText h3{
      margin: 0;
        font-size: 120% !important;
        color: #865dff;
        font-weight: 400;
        text-align: right;
        margin-top: 10%;
  }
  .PostFooterRight button{
    padding: 5px !important;
  }

    .profileLink {
      padding: 0.5rem;
      color: white;
      font-size: 12px !important;
    }

  .PostHeaderLeft ,
  .PostHeaderRight {
    display: flex;
  flex-direction: column !important;
  }
  .PostFooterRight button{
      display: flex;
      
      width: 60px !important;
      border-radius: 10px;
      padding: 6px !important;
      border-width: 0;
      /* background-color: rgba(0, 0, 0, 0.5); */
      margin-left: 2%;
      box-shadow: 2px 2px 10px black;
  }
    .editPostBtn{
      font-size: 100%;
        border-width: 0;
        display: flex;
        align-items: center;
        justify-content: center !important;
        padding: 10px !important;
        height: 47px;
        width: 60px !important;
        margin-left: 10px !important;
        gap: 2px;
        margin-right: 6px;
    }
}

@media only screen and (max-width: 420px) {
  .PostHeaderMiddle img {
    margin-left: 32% !important;
    width: 36% !important;
    height: 5%;
  }
  }
