.btn {
  width: 120px;
  height: 37px;
  left: 0;
  border-radius: 10px;

  background-color: rgba(137, 95, 255, 1);
  color: #fff;
  /* font-family: 'Baloo Bhai 2'; */
}
