@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

* {
    padding: 0;
    margin: 0;
}

.footer-center {
    margin-left: 5%;
}

.footer-center1 {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
}

html {
    background-color: #eaf0f2;
}

footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #5a30d5;
}

@media (max-height:800px) {
    footer {
        position: static;
    }

    header {
        padding-top: 40px;
    }
}

.footer-distributed {
    background-color: black;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 10px 50px 10px 50px;
    margin-top: 0px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
    width: 30%;
}

.footer-distributed h3 {
    color: #ffffff;
    font: normal 36px 'Cookie', cursive;
    margin: 0;
}


.footer-distributed h3 span {
    color: #865DFF;
}

/* Footer links */

.footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
}

.footer-distributed .footer-company-name {
    color: #865DFF;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

.footer-company-name {
    margin-left: 8% !important;
}

/* Footer Center */

.footer-distributed .footer-center {
    width: 35%;
}

.footer-distributed .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
}

.footer-distributed .footer-center a {
    font-size: 17px;
    color: white;
    text-decoration: none;
}

.footer-distributed .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
    font-size: 17px;
    font-weight: 300;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 17px;
    line-height: 2;
}

.footer-distributed .footer-center p a {
    color: #865DFF;
    text-decoration: none;
    ;
}

/* Footer Right */

.footer-distributed .footer-right {
    width: 30%;
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-distributed .footer-icons {
    margin-top: 25px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
}

.footer-distributed .footer-icons a:hover {
    background-color: #865DFF;
}

.footer-links a:hover {
    color: #865DFF;
}

.footer-links a {
    margin-right: 2%;
    margin-left: 2%;
}

.footer-center .fa-phone1{
    
}

@media (max-width: 600px) {
    footer {
        display: flex;
        flex-direction: column !important;
        border-top: 0px !important;
    }

    .footer-distributed .footer-left {
        display: flex !important;
        flex-direction: column;
        width: 100% !important;
        margin-bottom: 40px !important;
        text-align: center !important;
    }

    .footer-distributed .footer-left img {
        width: 60% !important;
        margin-left: 20%;
    }

    .footer-distributed .footer-center1,
    .footer-distributed .footer-right {
        display: block !important;
        width: 100% !important;
        margin-bottom: 40px !important;
        text-align: center !important;
    }

    .footer-distributed .footer-center1 i {
        margin-left: 0 !important;
    }

    .footer-center {
        display: none !important;
    }

    .footer-distributed .footer-center {
        width: 35%;
    }

    .footer-distributed .footer-center1 i {
        background-color: #33383b;
        color: #ffffff;
        font-size: 25px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin: 10px 15px;
        vertical-align: middle;
    }

    .footer-distributed .footer-center1 a {
        font-size: 17px;
        color: white;
        text-decoration: none;
    }

    .footer-distributed .footer-center1 i.fa-envelope {
        font-size: 17px;
        line-height: 38px;
    }

.footer-distributed .footer-center1 p {
        display: inline-block;
        color: #ffffff;
        vertical-align: middle;
        margin: 0;
        font-weight: 300;
    }

    .footer-distributed .footer-center1 p span {
        display: block;
        font-weight: normal;
        font-size: 17px;
        line-height: 2;
    }

    .footer-distributed .footer-center1 p a {
        color: #865DFF;
        text-decoration: none;
        ;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    footer {
        display: flex;
        flex-direction: row !important;
        border-top: 0px !important;
    }

    .footer-distributed .footer-left {
        display: flex !important;
        flex-direction: column;
        width: 100% !important;
        margin-bottom: 35px !important;
        text-align: center !important;
        margin-left: -4%;
    }

    .footer-distributed .footer-left img {
        width: 60% !important;
        margin-left: 10%;
    }

    .footer-distributed .footer-center1,
    .footer-distributed .footer-right {
        display: block !important;
        width: 100% !important;
        margin-bottom: 40px !important;
        text-align: center !important;
    }

    .footer-distributed .footer-center1 i {
        margin-left: 0 !important;
    }

    .footer-center {
        display: none !important;
    }

    .footer-distributed .footer-center {
        width: 35%;
    }

    .footer-distributed .footer-center1 i {
        background-color: #33383b;
        color: #ffffff;
        font-size: 25px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin: 10px 15px;
        vertical-align: middle;
    }

    .footer-distributed .footer-center1 a {
        font-size: 17px;
        color: white;
        text-decoration: none;
    }

    .footer-distributed .footer-center1 i.fa-envelope {
        font-size: 17px;
        line-height: 38px;
    }

    .footer-distributed .footer-center1 p {
        display: inline-block;
        color: #ffffff;
        vertical-align: middle;
        margin: 0;
    }

    .footer-distributed .footer-center1 p span {
        display: block;
        font-weight: normal;
        font-size: 14px;
        line-height: 2;
    }

    .footer-distributed .footer-center1 p a {
        color: #865DFF;
        text-decoration: none;
        ;
    }
    .footer-right .footer-company-about{
        margin-right: -4%;
        align-items: justify;
    }
}