.MainNavbar {
  background-color: #865dff;
  height: 8vh;
  display: flex;
  gap: 30px;
  align-items: center;
  border-radius: 0px 0px 15px 15px;
}

.navlogo {
  height: 60px;
  padding-left: 20px;
}

.searchbar {
  height: 40px;
  max-width: 300px;
  border-radius: 25px;
  background: #865dff22;
  border: 1px solid white;
  padding: 20px;
  color: #fff;
  background: url('http://kodyrabatowe.wp.pl/img/ico/search_gr.png') top left
    no-repeat;
  padding-left: 25px;
  transition: 0.1s;
}


.searchbar:focus{
    outline: none;
    border-width: 3px;
    
}

.notifs {
    color:#fff;
}


.collapse-content.collapsed {
    display: none;
  }
  
  .collapsed-content.expanded {
    display: block;
    position: sticky;
    top: 50px;
    width: 100%;
    background: white;
    color: black;
  }
.searchbar:focus {
  outline: none;
  border-width: 3px;
}
