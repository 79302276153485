.logbg {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: #222222;
}

.welcome {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  color: #ffffff;
}

.headings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.year {
  /* font-family: 'Baloo Bhai 2'; */
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 2% !important;
  margin-top: 0.5%;
  background: linear-gradient(88.14deg,
      #3f00fd 10%,
      #865eff 25.98%,
      #e284fe 55%,
      #ffce4f 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.inputset {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 15px;
  justify-content: space-around;
}

.inp {
  width: 350px;
  height: 30px;
  padding: 20px;
  background-color: #3a3b3c;
}

.inp:focus {
  outline: none;
}

.btn {
  margin: 0;
  padding: 20px 60px 20px 20px;
  transition: 0.3s ease-in-out;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  background-color: #3a3b3c;
  color: white;
}

.btn:hover {
  background-color: rgb(71, 71, 71);
  border-color: rgb(71, 71, 71);
}

.btn:disabled {
  background-color: rgb(71, 71, 71);
  border-color: rgb(71, 71, 71);
  cursor: not-allowed;
}

.selectbox {
  background-color: #3a3b3c;
  padding: 15px 40px;
  width: 50%;
}

.selectedbox {
  background-color: #865eff;
  padding: 15px 40px;
  width: 50%;
}

.selectbox:hover,
.selectedbox:hover {
  background-color: #865eff;
  cursor: pointer;
}

.form {
  margin: auto;
  padding: 20px 10px;
  color: #fff;
  background-color: #3a3b3c75;
}

input {
  border-radius: 10px;
}

.inp2 {
  width: 50%;
  margin-left: 4px;
  margin-right: 4px;
  height: 30px;
  margin-top: 20px;
}

.inp2:hover {
  border: 2px solid #e284fe;
}

.otp {
  width: 80%;
  height: 30px;
  border-radius: 5px;
  margin-top: 20px;
}

.otp:hover {
  border: 2px solid #e284fe;
}

/*  */
.selections {
  display: flex;
  justify-content: space-around;
}

/*  */

input {
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .logbg {
    width: 100%;
    top: 9%;
    transform: translate(-50%, 0);
  }

  .form {
    border: none;
  }

  .inputset {
    flex-wrap: wrap;
  }

  .inp {
    width: 100%;
  }

  .btn {
    width: 80%;
    padding: 20px 10px 20px 10px;
  }

  .welcome {
    margin-top: -4% !important;
  }

  .logbg {
    top: -4% !important;
  }

  .year {
    margin-bottom: 6% !important;
  }


}