.profilemain{
  background-color: #0000;
}
.cover_img{
  background-color: rebeccapurple;
  height: 250px;
  position: relative;
}

.cover_img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn{
  padding: 10px;
  background-color: #865dff;
  margin: 2px;
  border-radius: 20px;
}

.btn:hover{
  background-color: #6c6c6c;
  color: white;
}

.btnsContainer{
  display: flex;
  flex-direction: column !important;
}

.gallery_img div, .covershit{
  width: 100%;
  position: absolute;
  top: 0;
  bottom : 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 20px;
  background-color: #000;
  opacity: 0;
  color: white;
  text-transform: uppercase;
  border-radius: 38px;
}

.gallery_img{
  background-color: white;
}

.gallery_img:hover div, .covershit:hover{
  opacity: 0.8;
  cursor: pointer;
}

.profile_img img{

  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  
}
.profile_img{
  position: absolute;
  height: 200px;
  width: 200px;
  top: 100%;
  transform: translateY(-50%);
  left: 50px;

}

.profile_img :before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 50%;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.profile_body{
  padding: 20px;
  color: white;
  font-size: 18px;
  background-color: #232323;
}

.profile_head{
  display: flex;
  padding-left: 250px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gallery_images{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  padding-top: 50px;
  background-color: #232323;
}

.gallery_img{
  width: 300px;
  height: 200px;
  margin-bottom: 20px;
  position: relative;
  z-index: 0;
  border-radius: 38px !important;
  padding: 2px;
}

.gallery_img :before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 38px;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.gallery_img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  border-radius: 38px !important;
  padding: 2px;
}
.gallery_img img:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 38px;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.hidden_input{
  display: none;
}

@media screen and (max-width: 768px){
  .profile_head{
    padding-left: 0px;
    padding-top: 100px;
    flex-direction: column;

  }

  .profile_img{
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
.profile_info p{
  text-align: center;
}



}


@media screen and (max-width: 600px) {
  .btn {
    padding: 10px;
    background-color: #865dff;
    border-radius: 20px;
    margin-left: 35%;
    margin-right: 35%;
    width: 30%;
    
    margin-top: 5%;
    margin-bottom: 25%;
  }

    .socialshare {
      color: #fff !important;
      font-size: 24px;
    }
.socialcontainer {
  text-align: center;
  display: inline-block;
  padding: 5px;
  background-color: #865dff;
  position: relative;
  border-radius: 38px;
  width: 320px  !important;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 3%;
}
.tabContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #232323;
}

.tabButton {
  padding: 1% 1%;

  background-color: #865dff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 7%;
  height: 50px;
}

.activeTab {
  padding: 1% 1%;
  
  background-color: #ddd;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: #232323;
  margin-bottom: 7%;
  height: 50px;
}

.tabContent {
  margin-top: 20px;
}

}

.tabContainer {
  display: flex;
  justify-content: center;
  background-color: #232323;
}

.tabButton {
  padding: 1% 2%;
  margin-right: 20px;
  background-color: #865dff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.activeTab {
  padding: 10px 20px;
  margin-right: 20px;
  background-color: #ddd;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: #232323;
}

.tabContent {
  margin-top: 20px;
}

.socialIcons {
  display: flex;
  align-items: center !important;
  margin-top: 10px;
  justify-content: center;
}

.socialIcon {
  font-size: 34px;
  margin-right: 30px;
  color: #865dff;
}

.socialIcon:hover{
  color: white;
}
.socialcontainer {
  text-align: center;
  display: inline-block;
  padding: 5px;
  background-color: #865dff;
  position: relative;
  border-radius: 38px;
  width: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 8%;
}

.socialshare {
  color: #fff !important;
  font-size: 24px;
}

@media only screen and (min-width: 600px) and (max-width: 800px){
  .btn {
      padding: 10px;
      background-color: #865dff;
      border-radius: 20px;
      margin-left: 35%;
      margin-right: 35%;
      width: 30%;
  
      margin-top: 5%;
      margin-bottom: 25%;
    }
}



