.ChildContainer {
    display: flex;
  }
    .ChildContainer1 {
      display: none;
    }
  .Notif {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: black;
    color: #fff;
  }
  
  .active {
    position: fixed;
    top: 90px;
    background-color: black;
    opacity: 0.8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
      z-index 0s linear 0.01s;
    width: 100%;
    transform: translateY(-2em);
    overflow-y: hidden;
    overflow-x: hidden;
  }
  
  .notactive {
    position: fixed;
    top: 70px;
    background-color: #666;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: 0px;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
      z-index 0s linear 0.01s;
    width: 100%;
    transform: translateY(-2em);
    overflow-y: hidden;
    overflow-x: hidden;
  }
  
  .menu {
    text-decoration: none;
    font-size: 1.4rem;
    width: 100%;
  }
  
  .ParentContainer {
    display: flex;
    width: 100%;
    /* min-height: 100vh; */
    height: 100vh;
    background-color: #222222;
    padding-top: 8vh;
  }
  
  .mainContainer{
    display: flex;
    flex-direction: column;
    width: 65vw;
  }
  
    @media only screen and (max-width: 600px){
      .ChildContainer1 {
          display: flex;
        }
      .ChildContainer {
          display: none;
        }
    }