.Profilepic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
    border: 0.5px solid #865dff;
  }

  .profileContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1px;
  }

  .commenterName{
    color: #865dff;
  }

  .commentTime{
    color: gray;
    font-size: 50%;
    text-align: center;
    margin-left: 5px;
    margin-top: 2%;
  }