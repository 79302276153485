

.selectWrapper:hover{
  cursor: pointer;
}



.inputset {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 15px;
  justify-content: space-around;
}

.inp {
  width: 350px;
  height: 30px;
  padding: 20px;
  background-color: #3a3b3c;
}

.inp:focus {
  outline: none;
}

.inputsetspecial{
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-around;
}

.email{
  border-radius: 10px 0px 0 10px ;
  width: 200px;
  gap: 0px;
}

.emailtype{

  padding: 5px;
  background: #3a3b3c;
  color: white;
  border-left: 1px solid white;
  width: 150px;
  border-radius: 0px 10px 10px 0px;
}


.Btn {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 10px 50px;
  transition: 0.3s ease-in-out;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  background-color: #3a3b3c !important;
  color: white;
}
.Btn:hover {
  background-color: rgb(71, 71, 71) !important;
  border-color: rgb(71, 71, 71) !important;
}
.Btn:disabled {
  background-color: rgb(71, 71, 71) !important;
  border-color: rgb(71, 71, 71) !important;
  cursor: not-allowed;
}


.selectbox {
  background-color: #3a3b3c;
  padding: 15px 40px;
  width: 50%;
}

.selectedbox {
  background-color: #865eff;
  padding: 15px 40px;
  width: 50%;
}

.selectbox:hover, .selectedbox:hover {
  background-color: #865eff;
  cursor: pointer;
}



.form {

  margin: auto;
  padding: 20px 10px;
  color: #fff;
  background-color: #3a3b3c75;

}


.inp {
  border-radius: 10px;
}

.inp2 {
  width: 50%;
  margin-left: 4px;
  margin-right: 4px;
  height: 30px;
  margin-top: 20px;
}
.inp2:hover {
  border: 2px solid #e284fe;
}


.otp {
  width: 80%;
  height: 30px;
  border-radius: 5px;
  margin-top: 20px;
}
.otp:hover {
  border: 2px solid #e284fe;
}
/*  */
.selections {
  display: flex;
  justify-content: space-around;
}
/*  */


input {
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {

  .logbg{
    width: 85%;
    top: 10%;
    transform: translate(-50%, 0);
  }

  .form{
    border: none;
  }

  .inputset{
    flex-wrap: wrap;
  }

  .inp{
    width: 100%;
  }

  .btn{
    width: 100%;
  }

  .selectbox{
    width: 100%;
  }

  .selectedbox{
    width: 100%;
  }

}
