@import url('https://fonts.googleapis.com/css?family=Mukta:700');

.learn-more{
    border-width: 0;
}

.learn-more {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit !important;
  font-family: inherit;
  width: 98%;
  height: 0vh;
  margin-left: 1%;
  margin-right: 1%;
}

.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #865DFF;
  border-radius: 2.625rem;
}
.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.925rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.35rem;
  right: 0.0625rem;
  width: 0.725rem;
  height: 0.725rem;
  border-top: 0.225rem solid white;
  border-right: 0.225rem solid white;
  transform: rotate(45deg);
}
.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 0 0 0.5rem;
  margin: 0 0 0 1.85rem;
color: #865DFF;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  width: 95%;
  align-items: center;
}
.learn-more:hover .circle {
  width: 100%;
}
.learn-more:hover .circle .icon.arrow {
  background: white;
  transform: translate(1rem, 0);
}
.learn-more:hover .button-text {
  color: white;
}

.learn-more:hover .textbtn{
  background: transparent;
}

.textbtn{
  font-size: 25px !important;
}

@media only screen and (max-width: 600px){
  .learn-more{
    top: -750% !important;
    padding: 5%;
    height: 10% !important;
  }
  .learn-more .button-text {
    top: -45%;
  }
  .textbtn{
  font-size: 20px !important;
  margin-left: 10px !important;
}

.learn-more:hover .circle{
  width: 2.5rem !important;
  height: 3.5rem;
}
/* .learn-more .circle {
  top: 50px !important;
  width: 3rem;
  height: 3rem;
} */
}

