.container0 {
  width: 25vw;
  margin: 2vh auto;
  margin-left: 42% !important;
  margin-bottom: 15vh;
}

.container1 {
  width: 50vw;
  margin: 2vh auto;
  margin-left: 28% !important;
  margin-bottom: 15vh;
}
.container2 {
  width: 80vw;
  margin: 2vh auto;
  margin-left: 14% !important;
  margin-bottom: 15vh;
}
.container3 {
  width: 90vw;
  margin: 2vh auto;
  margin-left: 8% !important;
  margin-bottom: 15vh;
}
.container4 {
  width: 115vw;
  margin: 2vh auto;
  margin-left: 3% !important;
  margin-bottom: 15vh;
}
.row1 {
  width: 50%;
}
.meetteam{
  background-color: #232323 !important;
  overflow-x: hidden;
}.textMobile {
  display: none;
}

.teamTitle h2 {
  position: relative;
  color: rgba(0, 0, 0, .3);
  font-size: 75px !important ;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-top: 4.5%;
  margin-bottom: 4%;
  text-align: center;
}

.teamTitle h2:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 700px;
  white-space: nowrap;
  color: white;
  animation: loading 8s linear infinite;
}

@keyframes loading {
  0% {
    color: #4300fe;
  }

  25% {
    color: #895fff;
  }

  50% {
    color: #ffc700;
  }

  /* Change to desired color */
  100% {
    color: #4300fe;
  }
}

.team__item {
  width: 17vw;
  height: 17vw;
  position: relative;
  margin-bottom: 5vh;
  z-index: 0;
}

.team__item:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 38px;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.oc1 {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/oc1.jpg');
  border-radius: 38px;
  align-items: center !important;
  justify-content: center;
}
.oc2 {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/oc2.jpg');
  border-radius: 38px;
  align-items: center !important;
  justify-content: center;
}
.akash {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/web1.jpg');
  border-radius: 38px;
}
.pranita {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/web2.jpg');
  border-radius: 38px;
}
.shivesh {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/shivesh.jpg');
  border-radius: 38px;
}
.ashwani {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/webbachha2.jpg');
  border-radius: 38px;
}
.netra {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/netra.jpg');
  border-radius: 38px;
}
.nilabha {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/nilabha.jpg');
  border-radius: 38px;
}
.ansh {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/ansh.jpg');
  border-radius: 38px;
}
.darshan {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/darshan.jpg');
  border-radius: 38px;
}
.shreetosh {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/shreetosh.jpg');
  border-radius: 38px;
}
.adesh {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/adesh.png');
  border-radius: 38px;
}
.jay {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/design2.jpg');
  border-radius: 38px;
}
.priyanshi {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/mpr1.jpg');
  border-radius: 38px;
}
.vaibhavi {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/mpr2.jpg');
  border-radius: 38px;
}
.himanshu {
  /* background-position: center; */
  background-size: cover;
  background-image: url('../../assets/design1.jpg');
  border-radius: 38px;
}
.dhruv {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/dhruv.png');
  border-radius: 38px;
}
.om {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/om.jpg');
  border-radius: 38px;
}
.siddhant {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/siddhant.jpg');
  border-radius: 38px;
}

.akshat {
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/webbachha1.png');
  border-radius: 38px;
}
.team__item:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: '';
  z-index: -1;
  opacity: 0;
  transform: scale(0.75);
  
  background-size: 500% 500%;
  animation: animateGlow 6s ease infinite;
}
@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* .team__item:hover {
      background:rgba(34,48,96,0.8); 
      -webkit-transition:all,0.5s,ease-out,.5s;
      -o-transition:all,0.5s,ease-out,.5s;
      transition:all,0.5s,ease-out,.5s;
  } */



.team__text {
  height: 10vw;
  top: 17vw;
  position: relative;
  text-align: center;
}
.team__text .team__title {
  /* border-bottom: 3px solid rgba(183, 183, 183, 0.2); */
  padding-bottom: 0px;
  position: relative;
}
.team__text .team__title p {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
}
.team__text .team__title span {
  font-size: 17px;
  color: #fff;
  font-weight: 500;
}
.team__text .team__social {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 10px 0px;
  opacity: 1;
}
.team__text .team__social a {
  display: inline-block;
  font-size: 25px;
  color: #fff;
  margin-right: 28px;
  
}
.team__text .team__social a:last-child {
  margin-right: 0;
}
.team__text .team__social a:hover {
  color: #865dff;
}

.team_name {
  text-align: center;
  display: inline-block;
  padding: 10px;
  background-color: #865dff;
  position: relative;
  border-radius: 20px;
  margin-top: 60px !important;
  left: 50%;
  transform: translate(-50%, -50%);
}

.team_name_text {
  color: #fff !important;
  font-size: 40px;
}
@media (max-width: 800px) {
  .container0 {
    width: 100vw;
    margin: 5vh auto;
    margin-left: 0% !important;
  }

  .container1 {
    width: 100vw;
    margin: 5vh auto;
    margin-left: 0% !important;
  }
  .container2 {
    width: 100vw;
    margin: 5vh auto;
    margin-left: 0% !important;
  }

  .container3 {
    width: 100vw;
    margin: 5vh auto;
    margin-left: 0% !important;
  }

    .container4 {
      width: 115vw;
      margin: 2vh auto;
      margin-left: -7% !important;
      margin-bottom: 15vh;
    }
  .team__item {
    width: 90vw;
    height: 90vw;
    margin: 10vh auto;
    
    
  }
  .team__text {
    display: none;
  }
  .textMobile {
    display: block;
    height: 10vw;
    top: 90vw;
    position: relative;
    text-align: center;
  }
  .titleMobile {
    border-bottom: 1px solid rgba(183, 183, 183, 0.2);
    padding-bottom: 5px;
    position: relative;
  }
  .titleMobile p {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .titleMobile span {
    font-size: 17px;
    color: #fff;
    font-weight: 500;
  }
  .socialMobile {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 30px;
    margin: 8px auto;
  }
  .socialMobile a {
    display: inline-block;
    font-size: 20px;
    color: #fff;
    margin-right: 34px;
  }
  .line1{
    font-size: 100% !important;
    padding: 4vh 6vh !important;
  }
  .teamTitle h2{
    font-size: 40px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1000px){
  .teamTitle h2{
    margin-top: 10%;
  }
}

.Title h3 {
  color: white;
  text-align: center;
  font-size: 80%;
}

.Title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}


.line1 {
  color: #edb0ff;
  width: 80%;
  margin: 2vh auto;
  margin-top: -4%;
  padding: 2vh 5vw;
  text-align: center;
  font-size: large;
  font-size: 25px;
  border-bottom: 2px solid #7746ff;
}
.line2 {
  color: #fff;
  width: 80%;
  margin: 2vh auto;
  padding: 2vh 5vw;
  text-align: center;
  font-size: large;
  border-bottom: 2px solid #7746ff;
  border-top: 2px solid #7746ff;
}

.contactus{
  color: #edb0ff;
  font-size: 22px;
}



