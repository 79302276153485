.main-container-comments{
  width: 100%;
  border-radius: 20px;
  align-items: center;
  gap: 10px;
}

.tagLink{
  color:rgb(177, 218, 254);
}

.tagLink:hover{
  color:rgb(33, 151, 254) ;
}

.commentAndLikeContainer{
  display: flex;
  flex-direction: row;
}


.comment-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.comment-list-container{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
}

.comment {
  color: white;
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 15px;
  border-radius: 20px;
  margin: 5px;
  gap: 15px;
  animation: come-in 0.5s ease-in;
}

.comment--votes {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 8px 12px 10px;
  background: very-light-gray;
  border-radius: 10px;
}
/*
.comment--votes .plus-btn,
.comment--votes .minus-btn {
  background: transparent;
  border: 0;
  cursor: pointer;
} */
/* .comment--votes .plus-btn:hover svg path,
.comment--votes .minus-btn:hover svg path {
  fill: moderate-blue;
} */
/* .comment--votes .minus-btn {
  margin-top: -6px;
  margin-left: 0.5px;
}
.comment--votes .votes-counter {
  font-size: 1.1rem;
  margin-block: 8px;
  color: moderate-blue;
  font-weight: fw-medium;
} */
.comment--body {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  width: 100%;
}

.comment--body .comment--header {
  display: flex;
  align-items: center;
}

.comment--body .comment--header .profile-pic {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 10px;
}


.close-btn{
  color: white; 
  border-radius: 5px;
  border: white 0.5px;
  position: absolute;
  top: -20px;
  right: -20px;
} 

.close-btn:hover{
  color: red;
}

.comment--header{
  display: flex !important;
  justify-content: space-between !important;
  padding-top: 0%;
  padding-bottom: 1%;
}


.comment--body .comment--header .username {
  color: dark-blue;
  font-weight: fw-medium;
}

.comment--body .comment--header .you-tag {
  color: white;
  font-weight: fw-regular;
  font-size: 0.9rem;
  padding: 2px 10px 3px;
  background: moderate-blue;
  border-radius: 3px;
  margin-left: 10px;
}


.comment--body .comment--header .comment-posted-time {
  margin-left: 15px;
  color: grayish-blue;
}


.comment--body .comment--header .comment--btn {
  display: none;
}

.comment-content{
  width: 80% !important;
}


.comment--body .comment-content {
  width: 100%;
  color: grayish-blue;
  line-height: 1.5;
  word-wrap: break-word;
  word-break: break;
}

.comment--body .comment-content .replyingTo {
  color: moderate-blue;
  font-weight: fw-bold;
  display: inline-block;
  margin-right: 10px;
}


.comment--body .content-edit-box {
  height: 150px;
  color: grayish-blue;
  line-height: 1.5;
  padding: 10px 15px;
  border: 2px solid light-gray;
  border-radius: 10px;
  resize: none;
  scrollbar-width: none;
}


.comment--body .content-edit-box::-webkit-scrollbar {
  width: 0;
}
.comment--body .content-edit-box:focus {
  outline: 1.5px solid grayish-blue;
}

.comment--body .update-btn {
  align-self: flex-end;
  width: max-content;
  color: white;
  font-weight: fw-bold;
  text-transform: uppercase;
  padding: 15px 30px;
  background: moderate-blue;
  border: 0;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}
.comment--body .update-btn:hover,
.comment--body .update-btn:focus {
  opacity: 0.65;
}
.comment--footer {
  margin-left: 8%;
  margin-right: 2%;
  width: 10% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comment--footer .comment--votes {
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
}
.comment--footer .comment--votes .votes-counter {
  margin-block: 0;
  margin-inline: 15px;
}
.comment--footer .comment--votes .minus-btn {
  height: 25px;
}
.comment--btn {
  display: flex;
  align-items: center;
  /* background-color: black; */
  color: white;
  gap: 10%;
  margin-left: auto;
  margin-right: 2%;
}
.add-btn{
  background-color: black;
}
.add-btn:hover{
  opacity: 0.5;
}

.delete-btn{
  width: 7% !important;
  display: flex;
  align-items: center;
  align-items: center;
  gap: 2px;
  border-width: 0;
  margin: -5%;
  margin-top: -8%;
}

.view-comments-container{
  width: 100%;
  display: flex;
}

.view-comments{
  width: 25%;
  border-width: 0;
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 3% !important;
}

.view-comments:hover{
  opacity: 0.5;
}

.like-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2px;
}


.like-btn:hover{
  opacity: 0.5;
}

.delete-btn:hover{
  opacity: 0.5;
}
.edit-btn{
  width: 100% !important;
  background-color: black !important;
}

.comment--btn button {
  display: flex;
  align-items: center;
  gap: 5px;
  color: moderate-blue;
  font-weight: fw-bold;
  padding: 6px;
  background: transparent;
  border: none;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}

.comment--btn button:hover,
.comment--btn button:focus {
  opacity: 0.5;
}
.comment--btn .delete-btn {
  color: soft-red;
}
.reply-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 15px;
  border-left: 2px solid light-gray;
  margin-left: 15px;
  gap: 15px;
}
.reply-container .comment-container {
  width: 100%;
}
.reply-container .comment-container .comment {
  animation: come-in 1.5s ease-in-out;
}
.add-comment {
  /* margin-top: 5px; */
  animation-duration: 0.1s;
}
.reply-container-gap .reply-container {
  margin-top: 15px;
}
@keyframes come-in {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  75% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


