.navbar {
  position: fixed !important;
  top: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  z-index: 99;
  background-color: #865dff;
}
.dropdownProfile{
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  margin-top: 5%;
  right: 20px;
  padding: 15px;
  border-radius: 10px;
  color: white;
  width: 12%;
}

.profileNav{
  margin: 0 !important;
  padding: 0 !important;
}

.profileNav:hover{
  opacity: 0.6;
  background-color: transparent !important;
}

.particularUser{
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  gap: 4px;
  margin: 5px;
}

.hostel{
  font-size: 80%;
  color: #865dff;
}



.mainList{
  display: block;
  list-style: none outside;
  padding: 0.1rem 1rem;
  border-radius: 10px;
  width: 100%;
  animation: slidein 0.3s ease 1 both;
}

.mainList:hover{
  background-color: #865dff;
}

.mainBox .mainList a:hover{
  color: white;
}

.ProfilePicNav {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white !important;
}

.ProfilePicNav:hover{
  cursor: pointer;
}

.Profilepic{
  background-color: white;
  width: 40px !important;
  height: 40px !important;
}

.dept{
  color: #865dff;
  font-size: 80%;
}

.mainBox .mainList:hover .dept{
  color: black !important;
}
.mainBox .mainList:hover .hostel{
  color: black !important;
}

.mainBox .mainList a{
  display:block;
  text-decoration: none;
}


.ProfilePic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #865dff;
}


@keyframes slidein{
  from{
    transform: translateX(-10rem);
    opacity: 0;
  }
  
  to{
    transform: translate(0);
    opacity: 1;
  }
}

.mainBox li:nth-child(2) {animation-delay: 0.15s}
.mainBox li:nth-child(3) {animation-delay: 0.30s}
.mainBox li:nth-child(4) {animation-delay: 0.45s}
.mainBox li:nth-child(5) {animation-delay: 0.60s}
.mainBox li:nth-child(6) {animation-delay: 0.75s}
.mainBox li:nth-child(7) {animation-delay: 0.90s}


.usersList{
  position: absolute;
  width: 32vw;
  top: 100%;
  left: 13%;
  padding: 1%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 20px;
}

.navbar__logo {
  height: 100%;
}

.navbar__search {
  margin-left: 0 !important;
  width: 20%;
  background-color: white;
  padding: 2px;
  padding-left: 10px;
  border: 2px solid grey;
  color: white;
  border-radius: 30px;
}

.notif {
  color: #fff;
  cursor: pointer;
}

.navbar__links a:hover {
  background-color: #fff;
  color: #865dff;
}



.navbar__links a {
  font-size: 110%;
}

.navbar__links svg {
  margin-right: 5px;
  fill: #fff;
}

.navbar__links a:hover svg {
  fill: #865dff;
}

.navbar__yb_logo {
  display: flex;
  width: 2.3%;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}

.navbar__search {
  margin-left: -8%;
  margin-right: 8%;
}

.navbar__search input {
  display: flex;
  flex-direction: column;
  padding: 10%;
  border-radius: 38px;
  border: none;
  width: 210%;
  font-size: 1rem;
  background-color: #fff;
}

.navbar__links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar__links li {
  margin-right: 10px;
}

.navbar__links a {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
}

.navbar1 {
  display: none;
}

@media only screen and (max-width: 700px) {
  .navbar {
    display: none !important;
    
  }

  .navbar__logo {
    display: none;
  }

  .navbar__links {
    display: none !important;
  }

  .navbar1 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #865fff;
    padding: 10px;
    height: 60px !important;
    z-index: 99 !important;
  }

  .navbar-logo1 img {
    width: 20px;
  }

  .navbar__yb_logo1 {
    display: flex;
    width: 3%;
    font-weight: bold;
    height: 3%;
  }

  .navbar-search1 {
    flex-grow: 1;
    margin: 0 10px;
  }

  .navbar-search1 input {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 16px;
    background-color: #fff !important;
  }

  .navbar-links1 {
    position: relative;
  }

  .navbar-links1 a {
    color: #fff !important;
  }

 

  .navbar-links1 a:hover svg {
    fill: #865dff;
  }

  .navbar-links1 a svg {
    margin-right: 5px;
    fill: #fff !important;
  }

  .navbar-links1:hover svg {
    margin-right: 5px;
    fill: #865dff !important;
  }

  .navbar-hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    width: 25px;
    height: 20px;
  }

  .navbar-hamburger span {
    width: 100%;
    height: 2px;
    background-color: #000;
    border-radius: 5px;
  }

  .navbar-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #865fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  .navbar-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .navbar-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .navbar-list li:hover {
    background: black;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -5px;
    margin-bottom: 5px;
    padding: 10px;
  }

  .navbar-list a {
    color: #000;
    text-decoration: none;
    margin-right: 5px;
  }

  .navbar-icon {
    font-size: 20px;
  }
}


@media only screen and (min-width: 700px) and (max-width: 900px){
  .navbar {
      display: none !important;
  
    }
  
    .navbar__logo {
      display: none;
    }
  
    .navbar__links {
      display: none !important;
    }
  
    .navbar1 {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #865fff;
      padding: 10px;
      height: 60px !important;
      z-index: 99 !important;
    }
  
    .navbar-logo1 img {
      width: 15px;
    }
  
    .navbar__yb_logo1 {
      display: flex;
      width: 4%;
      font-weight: bold;
      height: 80%;
    }
  
    .navbar-search1 {
      flex-grow: 0.5;
      margin: 0 10px;
    }
  
    .navbar-search1 input {
      width: 100%;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      font-size: 16px;
      background-color: #fff !important;
    }
  
    .navbar-links1 {
      position: relative;
    }
  
    .navbar-links1 a {
      color: #fff !important;
    }
  
    .navbar-links1 a:hover {
      background-color: #fff;
      color: #865dff ;
    }
  
    .navbar-links1 a:hover svg {
      fill: #865dff;
    }
  
    .navbar-links1 a svg {
      margin-right: 5px;
      fill: #fff ;
    }
  
    .navbar-links1:hover svg {
      margin-right: 5px;
      fill: #865dff !important;
    }
  
    .navbar-hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      width: 25px;
      height: 20px;
    }
  
    .navbar-hamburger span {
      width: 100%;
      height: 2px;
      background-color: #000;
      border-radius: 5px;
    }
  
    .navbar-dropdown {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: #865fff;
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }
  
    .navbar-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  
    .navbar-list li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  
    .navbar-list a {
      color: #000;
      text-decoration: none;
      margin-right: 5px;
    }
  
    .navbar-icon {
      font-size: 20px;
    }
}

@media only screen and (min-width: 899px) and (max-width: 1150px){
    .navbar__search{
      margin-left: 0;
      width: 15%;
      background-color: white;
      padding: 2px;
      padding-left: 10px;
      border: 2px solid grey;
      color: white;
      border-radius: 30px;
    }

.navbar__links a {
  font-size: 90%;
}

.navbar__links svg {
  margin-right: 5px;
  fill: #fff;
}

.navbar__links a:hover svg {
  fill: #865dff;
}


.navbar__yb_logo {
  display: flex;
  width: 3%;
  font-weight: bold;
  
  margin-left: -60%;
}

.navbar__search {
  margin-left: -15%;
  margin-right: 60%;
}

.navbar__search input {
  display: flex;
  flex-direction: column;
  padding: 10%;
  border-radius: 38px;
  border: none;
  width: 210%;
  font-size: 1rem;
  background-color: #fff;
}

.navbar__links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar__links li {
  margin-right: 10px;
}

.navbar__links a {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
}

.navbar1 {
  display: none;
}

}