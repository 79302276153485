.FeedContainer {
    width: 100%;
    width: 65vw;
    background-color: #222222;
    padding-top: 30px;
    overflow-y: scroll;
    transition: left 0.5s ease;
    align-items: center;
    justify-content: center;
}

.FeedContainer::-webkit-scrollbar {
    width: 0px;
}

.FeedContainer::-webkit-scrollbar-thumb {
    background-color: #865dff;
    border-radius: 10px;
}

.FeedContainer::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}


.nextButton{
    position: relative;
    background-color: white;
    margin-bottom: 10%;
    margin-right: 10%;
    padding: 1%;
    width: 20%;
    background-color: #865dff;
    color: black;
    border-radius: 10px;
  }
  
  .nextButton:hover{
    opacity: 0.7;
  }





.Title h3 {
    color: white;
    text-align: center;
    font-size: 130%;
}

.Title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.Title h2 {
    position: relative;
    color: rgba(0, 0, 0, .3);
    font-size: 80px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    margin: 0%;
}

.Title h2:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    max-width: 700px;
    white-space: nowrap;
    color: white;
    animation: loading 8s linear infinite;
}


.loader {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid #865dff; /* Blue border on top */
  border-radius: 50%; /* Make it round */
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite; /* Apply animation */
  margin: 20px auto; /* Center the loader */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes loading {
    0% {
        color: #4300fe;
    }

    25% {
        color: #895fff;
    }

    50% {
        color: #ffc700;
    }

    /* Change to desired color */
    100% {
        color: #4300fe;
    }
}



.line1 {
    padding: 3%;
    color: #edb0ff;
}

.btnContainer1 {
    display: none;
}

.feed_button {
    display: none !important;
}



@media only screen and (max-width: 600px) {
    .Title h2 {
        font-size: 20px !important;
    }

    .FeedContainer {
        width: 150%;
        padding-top: 10px !important;
        margin-top: -20%;
    }

    .btnContainer1 {
        display: flex;
    }

    .line1 {
        padding: 2% !important;
        text-align: justify;
    }

    .feed_button {
        border-radius: 38px;
        background-color: purple !important;
        color: white !important;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
    }

    .feed_button:hover {
        background-color: yellow;
        color: white;
    }

    .feed_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh !important;
    }


}