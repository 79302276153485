@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@900&display=swap');
.headerContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: aliceblue;
}


.Title h2 {
    text-align: center;
    position: relative;
    color: rgba(0, 0, 0, .3);
    font-size: 80px !important;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    margin: 3%;
    margin-top: 0 !important;
  }
  
  .Title h2:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    max-width: 700px;
    white-space: nowrap;
    color: white;
    animation: loading 8s linear infinite;
  }
  
  @keyframes loading {
    0% {
      color: #4300fe;
    }
  
    25% {
      color: #895fff;
    }
  
    50% {
      color: #ffc700;
    }
  
    /* Change to desired color */
    100% {
      color: #4300fe;
    }
  }
  
  
  
  .line2 {
    color: #edb0ff;
    text-align: justify;
    margin: 0 5% 0 5%;
  }

  @media only screen and (max-width: 600px){
    .Title h2{
      font-size: 40px !important;
      top: 10px !important;
    }
    .line2{
      margin: 2% !important;
      top: 20% !important;
      
    }
  }
  
 