.editprofilemain{
  background-color: #232323;
  padding-top: 2%;
  padding-bottom: 5%;
}

.edit-form{
  color: white;
  margin: auto;
  width: 80%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profilehead .proimg{
  display: inline;
  width: 150px;
  height: 150px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 0;
  border-radius: 10px !important;
}

.profilehead .proimg:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius:10px !important;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.proimg{
  margin-top: 10%;
}

.proimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.proimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
  border-radius: 10px !important;
  padding: 2px;
}

.proimg img:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 10px ;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.proimg div{
  width: 100%;
  position: absolute;
  top: 0;
  bottom : 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 20px;
  background-color: #3a3b3c;
  opacity: 0;
}

.proimg:hover div{
  opacity: 0.8;
  cursor: pointer;
}

#image{
  display: none;
}

.profilehead-text label{
  display: block;
}

.profilehead-text{
  padding-left: 30px;
}

.profilehead-text p{
  font-size: 20px;
  font-weight: bold;
  padding: 10px;

}

.extracurri{
  height: 200px;
}

.inputbox{
  padding: 10px;
  width: 100%;

}

.profilehead{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: inline !important;
  width: auto !important;
}

.edit-form label{
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  width: 150px
}

.edit-form input, .edit-form textarea{
  height: 30px;
  padding: 20px;
  background-color: #3a3b3c;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  margin: 0 10px 0 10px;
  min-width: 200px;
}

.edit-form input:focus{
  outline: 1px solid white;
}

.edit-form select{
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 5px;
  background: #3a3b3c;
  color: white;
  border-left: 1px solid white;
  border-radius: 0px 10px 10px 0px;
  height: 40px;
  min-width: 200px;
  margin: 0 10px 0 10px;
}

.section-header{
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
  margin-top: 20px;
}

.section-body{
  position: relative;
  width: 100%;
}

.section-break{
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
  padding: 10px;
}

.section-break {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
  border-radius: 20px !important;
  padding: 2px;
}

.section-break:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 20px;
  background: linear-gradient(to right, #4300fe, #7746ff, #895fff, #ffc700);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

.btn {
  display: flex;
  justify-content: center;
  margin: 20px;
  padding: 10px 100px !important;
  transition: 0.3s ease-in-out;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  background-color: #3a3b3c !important;
  color: white !important;
  margin-bottom: 1%;
}

.btn:hover {
  background-color: rgb(71, 71, 71) !important;
  border-color: rgb(71, 71, 71) !important;
}
.btn:disabled {
  background-color: rgb(71, 71, 71);
  border-color: rgb(71, 71, 71);
  cursor: not-allowed;
}

.inputbox textarea::-webkit-scrollbar {
  display: none;
}

.inputbox textarea{
  height: 100px;
  padding: 20px;
  background-color: #3a3b3c;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  margin: 0 10px 0 10px;
  min-width: 200px;
  /* resize: none; */
}


@media screen and (max-width: 1200px) {
  .inputbox{
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
}

@media screen and (max-width: 750px) {
  .profilehead .proimg{
    width: 100px;
    height: 100px;
  }
  .edit-form{
    width: 100%;
  }

  .edit-form label{
    width: auto;
    display: block;
  }
  .edit-form input{
    width: 90%;
  }


  .inputbox{
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}


