@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@900&display=swap');

.mainContainer {
  padding-top: 11%;
  background-color: #222222 !important;
}

body::-webkit-scrollbar {
  width: 0;
}


.both {
  display: flex;
}

/*  */

.about {
  /*  */
  width: 40%;
  margin-top: 30px;
  height: 70%;
  margin-left: 3%;
  margin-right: 3%;
  flex: 1;
  margin-bottom: 0.5%;
}

.aboutimg {
  /*  */
  flex: 1;
  width: 40%;
  margin-left: 1%;
  margin-right: 1%;
  height: 80%;
  margin-bottom: 6%;
}


/* .heading{

} */
.welcome {
  margin-left: 4%;
  margin-right: 0%;
  margin-bottom: 2%;
  font-style: normal;
  font-weight: 400;
  font-size: 300%;
  color: #ffffff;
}

body {
  /* font-family: sans-serif; */
}

.Title h2 {
  position: relative;
  color: rgba(0, 0, 0, .3);
  font-size: 80px !important;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 7%;
  margin-left: 4%;
}

.Title h2:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 700px;
  white-space: nowrap;
  color: white;
  animation: loading 8s linear infinite;
}

@keyframes loading {
  0% {
    color: #4300fe;
  }

  25% {
    color: #895fff;
  }

  50% {
    color: #ffc700;
  }

  /* Change to desired color */
  100% {
    color: #4300fe;
  }
}

.year {
  margin-bottom: 30px;
  margin-top: -40px;
  margin-left: 30px;
  margin-right: 30px;
  /* font-family: Arial,sans-serif; */

  font-weight: 900;
  font-size: 90px;

  background: linear-gradient(88.14deg,
      #3f00fd 10%,
      #865eff 25.98%,
      #e284fe 55%,
      #ffce4f 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.abouttext {
  margin-top: -40px;
  margin-left: 30px;
  margin-bottom: 50px;
  margin-right: 30px;
  font-style: normal;
  font-weight: 0;
  font-size: 22px;
  line-height: 26px;
  text-align: justify;
  color: #ffffff;
}

.images {
  width: 100%;
  height: 500px;
  padding: 10% 3% 0% 2%;

  margin: auto;
  margin-top: 30px;
  display: block;
}

/* phone view */

@media only screen and (max-width: 600px) {
  .Title h2 {
    font-size: 55px !important;
    margin-left: 5.5%;
    margin-bottom: 10%;
    margin-top: -7%;
  }

  .both {
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    margin: auto;
  }

  .about {
    /*  */
    width: 94%;
    margin-top: 0;
    height: 50vh;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 40px;
    flex: 1;
  }

  .aboutimg {
    /*  */
    flex: 1;
    width: 90%;
    margin-top: -9%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 10px;
    height: 50vh;
  }

  .welcome {
    margin-left: 30px;
    margin-bottom: 30px;
    margin-right: 30px;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 30px !important;
    color: #ffffff;
  }

  .year {
    margin-top: -40px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 60px;
    /* font-family: 'Baloo Bhai 2'; */
    font-style: normal;
    font-weight: 600;
    font-size: 50px;

    background: linear-gradient(88.14deg,
        #3f00fd 10%,
        #865eff 25.98%,
        #e284fe 55%,
        #ffce4f 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .images {
    width: 100%;
    height: 50vh;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    margin-bottom: 60px;
  }
}

/* ipad */

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .Title h2 {
    font-size: 35px ;
    margin-left: 4.5%;
    margin-bottom: 6%;
    margin-top: -2%;
  }

  .both {
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    margin: auto;
  }

  .about {
    /*  */
    width: 94%;
    margin-top: 0;
    height: 50vh;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 40px;
    flex: 1;
  }

  .aboutimg {
    /*  */
    flex: 1;
    width: 90%;
    margin-top: -9%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 10px;
    height: 50vh;
  }

  .welcome {
    margin-left: 4%;
    margin-bottom: 3%;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 250% !important;
    color: #ffffff;
    margin-top: -5%;
  }

  .year {
    margin-top: -40px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 60px;
    /* font-family: 'Baloo Bhai 2'; */
    font-style: normal;
    font-weight: 600;
    font-size: 50px;

    background: linear-gradient(88.14deg,
        #3f00fd 10%,
        #865eff 25.98%,
        #e284fe 55%,
        #ffce4f 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .images {
    width: 100%;
    height: 60vh;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 900px){
  .Title h2 {
    font-size: 55px !important;
    margin-left: 3.5%;
    margin-bottom: 8%;
    margin-top: -5%;
  }

  .both {
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    margin: auto;
  }

  .about {
    /*  */
    width: 94%;
    margin-top: 0;
    height: 50vh;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 40px;
    flex: 1;
  }

  .aboutimg {
    /*  */
    flex: 1;
    width: 90%;
    margin-top: -9%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 10px;
    height: 50vh;
  }

  .welcome {
    margin-left: 3%;
    margin-bottom: 6%;
    margin-right: 3%;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 30px !important;
    color: #ffffff;
    margin-top: -4%;
  }


  .images {
    width: 100%;
    height: 60vh;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1000px){
.Title h2 {
    font-size: 50px !important;
    margin-left: 4%;
    margin-bottom: 10%;
    margin-top: -5%;
  }

  .both {
    display: flex;
    flex-direction: row;
    margin-bottom: 4%;
    margin: auto;
  }

  .about {
    /*  */
    width: 94%;
    margin-top: 0;
    height: 50vh;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 40px;
    flex: 1;
  }

  .aboutimg {
    /*  */
    flex: 1;
    width: 90%;
    margin-top: -9%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 10px;
    height: 50vh;
  }

  .abouttext{
    margin-right: 2%;
  }

  .welcome {
    margin-left: 4%;
    margin-bottom: 6%;
    margin-right: 3%;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 35px ;
    color: #ffffff;
    margin-top: 0%;
  }


  .images {
    width: 100%;
    height: 70vh;
    margin: auto;
    display: block;
    margin-bottom: 50px;
    margin-top: 30%;
  }
}