@import url('https://fonts.googleapis.com/css?family=Mukta:700');

.learn-more{
    border-width: 0;
}



.learn-more {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  width: 98%;
  height: 10vh;
  margin-left: 1%;
  margin-right: 1%;
}

.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #865DFF;
  border-radius: 2.625rem;
}
.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.925rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.35rem;
  right: 0.0625rem;
  width: 0.725rem;
  height: 0.725rem;
  border-top: 0.225rem solid white;
  border-right: 0.225rem solid white;
  transform: rotate(45deg);
}


.learn-more {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  /* position: absolute; */
  /* top: -15%; */
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  /* padding: 1rem 0 0 0.5rem; */
  /* margin: 0 0 0 1.85rem; */
color: #865DFF;
  font-weight: 700;
  /* line-height: 1.6; */
  text-align: center;
  text-transform: uppercase;
  /* width: 95%; */
  /* align-items: center; */
}

.buttontext{
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  top: -60%;
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  /* padding: 1rem 0 0 0.5rem; */
  /* margin: 0 0 0 1.85rem; */
color: #865DFF;
  font-weight: 700;
  /* line-height: 1.6; */
  /* text-align: center; */
  text-transform: uppercase;
  /* width: 95%; */
  /* align-items: center; */
}



.learn-more:hover .circle {
  width: 100%;
}
.learn-more:hover .circle .icon.arrow {
  background: white;
  transform: translate(1rem, 0);
}
.learn-more:hover .buttontext {
  color: white;
}

.learn-more:hover .textbtn{
  background: transparent;
}

.textbtn{
  font-size: 25px;
}

@media only screen and (max-width: 600px){
   .textbtn {
      font-size: 18px !important;

    }
  /*.learn-more .buttontext {
    font-weight: 500;
    top: 350px;
    
    width: 130%;
    margin: 0 0 0 -15px;
  }
  .learn-more .circle{
    top: 350px !important;
    width: 3rem;
    height: 3rem;
  }*/


  .learn-more:hover .circle{
    width: 100% !important;
  } 

    .learn-more .circle {
      transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
      position: relative;
      display: block;
      margin: 0;
      width: 3.5rem;
      height: 3.5rem;
      background-color: #865DFF;
      border-radius: 2.625rem;
      top: -45%;
    }

.learn-more .buttontext {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 400px !important;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 0 0 0.5rem;
  margin: 0 0 0 2.85rem;
color: #865DFF;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  width: 95%;
  align-items: center;
}

.learn-more{
  margin-top: -2px;
}


    .circular-button {
      position: absolute;
      top: 75px !important;
      margin-left: 10%;
     
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #ffc700;
    }
  
    .popup {
      position: fixed;
      top: 10%;
      left: 10%;
      width: 80%;
      height: 80%;
      background-color: black;
      padding: 20px;
      z-index: 999;
      overflow: auto;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
  
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
    }
  
    .popup-content {
      width: 100%;
      height: 100%;
      border: none;
    }
}

