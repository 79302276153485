@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@900&display=swap');
.FeedContainer {
  background-color: #222222;
  padding-top: 0px;
  overflow-y: scroll;
  width: 83%;
}

.mainContainer{
  min-height: 100%;
}

.FeedContainer::-webkit-scrollbar {
  width: 0px;
}

.FeedContainer::-webkit-scrollbar-thumb {
  background-color: #865dff;
  border-radius: 10px;
}

.FeedContainer::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.formLink:hover{
  color: #4300fe !important;
}

.Title h3 {
  color: white;
  text-align: center;
  font-size: 130%;
}

.Title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.Title h2 {
    position: relative;
    color: rgba(0, 0, 0, .3);
    font-size: 80px !important;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    margin: 2%;
}
.Title h2:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    max-width: 700px;
    white-space: nowrap;
    color: white;
    animation: loading 8s linear infinite;
}
@keyframes loading {
        0% {
          color: #4300fe;
        }
    
        25% {
          color: #895fff;
        }

        50%{
          color: #ffc700;
        }
    
        /* Change to desired color */
        100% {
          color: #4300fe;
        }
}

.loader {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid #865dff; /* Blue border on top */
  border-radius: 50%; /* Make it round */
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite; /* Apply animation */
  margin: 20px auto; /* Center the loader */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.line1{
    color: #edb0ff;
    padding: 0% 4% 3% 4%;
    text-align: justify;
}

.pollsformcontainer {
  text-align: center;
  display: inline-block;
  padding: 5px;
  background-color: #865dff;
  position: relative;
  border-radius: 38px;
  
  left: 50%;
  transform: translate(-50%, -50%);
}

.pollsformtext {
  color: #fff !important;
}

/* @media screen (max-width: 768px){
  .FeedContainer{
    width: 100%;
    }
} */

@media (max-width: 768px){
  .FeedContainer{
    width: 100%;
    margin-top: -12%;
  }
  .line1{
    margin-bottom: 8%;
  }

}

