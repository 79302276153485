@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,200,300,600,700);

#specials,
#sports,
#type{
  margin: 10% 0;
  position: relative;
  clear: both;
}

.headings{
    margin-bottom: 0px !important;
    color: #865dff;
    font-size: x-large;
}

#sports h4,
#type h4 {
  font-size: 0.8em;
  display: inline-block;
  margin: 4px 8px;
  clear: none;
}

#type {
    margin-top: 0.3%;
    border-radius: 7px;
    border-top-right-radius: 0px;
}

#sports .check-box,
#type .check-box {
  float: left;
}

.box1 {
  min-width: 120px;
  margin: 5px 0;
  clear: none;
  color: #b1b1b1;
}

#sports .box1 {
  float: left;
  margin: 4px 0;
}


.bump {
  margin-left: 5%;
  position: relative
}


/*CHECK BOXES*/
/* Checkmark style starts */

@-moz-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 14px;
  }
}

@-webkit-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 14px;
  }
}

@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 14px;
  }
}

@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 25px;
  }
}

@-webkit-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 25px;
  }
}

@-moz-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 25px;
  }
}

input[type=checkbox] {
  display: none;
}

.check-box {
  height: 2.5vh;
  width: 9%;
  background-color: transparent;
  border: 2px solid #838383;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: border-color ease 0.2s;
  -o-transition: border-color ease 0.2s;
  -webkit-transition: border-color ease 0.2s;
  transition: border-color ease 0.2s;
  cursor: pointer;
}


.check-box::before,
.check-box::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  height: 0;
  width: 3.5px;
  background-color: #865dff;
  display: inline-block;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 2px;
  content: ' ';
  -webkit-transition: opacity ease .5;
  -moz-transition: opacity ease .5;
  transition: opacity ease .5;
}

.check-box::before {
  top: 15px;
  left: 10px;
  /* box-shadow: 0 0 0 3px #efefef; */
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.check-box::after {
  top: 5px;
  left: 0.1px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type=checkbox]:checked + .check-box,
.check-box.checked {
  border-color: #865dff;
}

input[type=checkbox]:checked + .check-box::after,
.check-box.checked::after {
  height: 8px;
  -moz-animation: dothabottomcheck 0.2s ease 0s forwards;
  -o-animation: dothabottomcheck 0.2s ease 0s forwards;
  -webkit-animation: dothabottomcheck 0.2s ease 0s forwards;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}

input[type=checkbox]:checked + .check-box::before,
.check-box.checked::before {
  height: 60px;
  -moz-animation: dothatopcheck 0.4s ease 0s forwards;
  -o-animation: dothatopcheck 0.4s ease 0s forwards;
  -webkit-animation: dothatopcheck 0.4s ease 0s forwards;
  animation: dothatopcheck 0.4s ease 0s forwards;
}

.grey {
  color: rgb(51, 51, 51) !important;
}

.fixed {
  position: fixed;
  top: 0;
  height: 55px;
  z-index: 1;
  width:100%;
}




#type::-webkit-scrollbar {
    width: 3px;
  }
  
  #type::-webkit-scrollbar-thumb {
    background-color: #865dff;
    border-radius: 10px;
  }
  
  #type::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }
  