.bttn {
  /* font-family: 'Inter'; */
  width: 20%;
  height: 10%;
  margin-left: 5%;
  font-style: normal;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #fff;
  border: none;
  position: relative;
  z-index: 0;
  padding: 2%;
  font-size: 120%;
}

.bttn:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2%;
  border-radius: 38px;
  background: linear-gradient(to right,
      #3f00fd 10%,
      #865eff 25.98%,
      #e284fe 55%,
      #ffce4f 75%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}